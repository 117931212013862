<template>
  <div class="cloud-news">
    <div class="container">
      <AddressPosition></AddressPosition>
      <div class="main">
        <div class="news-list">
          <div class="item" v-for="(item, index) in list" :key="index" @click="handleDetail(item.id)">
            <el-image class="cover" :src="item.newsCover" fit="cover"></el-image>
            <div class="info">
              <div class="topic">{{item.newsTitle}}</div>
              <div class="desc">{{item.newsAbstract}}</div>
              <div class="bottom">
                <!-- <span>作者：{{item.newsSource}}</span> -->
                <span>发布时间：{{item.publishedTime}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="page"
          :limit.sync="limit"
          @pagination="getListData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AddressPosition from '@/components/AddressPosition'
import Pagination from '@/components/Pagination'
import { NewsList } from '@/api'

export default {
  name: 'CloudNewsList',
  components: {
    AddressPosition, Pagination
  },
  data() {
    return {
      list: [],
      page: 1,
      limit: 10,
      total: 0
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0
    this.getListData()
  },
  methods: {
    getListData() {
      NewsList({
        pageNum: this.page,
        pageSize: this.limit,
        newsType: '3',
        // newsState: '1'
      }).then(res => {
        let { content } = res.data
        content.map(item=>{
          if (item.publishedTime)
          item.publishedTime = item.publishedTime.split(' ')[0]
          if (item.newsCover.indexOf('http')==-1) {
            item.newsCover = `http://img.miaocang.cc/${item.newsCover}`
          }
          return item
        })
        this.list = content
        this.total = res.data.total
      })
    },
    paginationInitList(val) {
      this.page = val
      this.getListData()
    },
    handleDetail(id) {
      const routeData = this.$router.resolve({
        name: 'CloudNewsDetail',
        query: { article: id }
      })
      window.open(routeData.href)
    }
  }
};
</script>

<style lang="less">
.cloud-news {
  & > .container {
    width: 1200px;
    min-height: 1000px; /**待优化 */
    margin: 0 auto;
  }
  .main {
    .news-list {
      .item {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 30px;
        cursor: pointer;
        &:hover {
            .info > .topic {
                color: #20a162;
            }
        }
        .cover {
          width: 360px;
          height: 220px;
        }
        .info {
          width: 768px;
          margin-left: 24px;
          .topic {
            font-size: 20px;
            color: #333;
            margin-bottom: 24px;
          }
          .desc {
            height: 150px;
            line-height: 1.5;
            font-size: 16px;
            color: #666;
            overflow: hidden;
          }
          .bottom {
            font-size: 14px;
            color: #999;
          }
        }
      }
    }
  }
}
</style>
